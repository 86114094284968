import React from 'react'
import { Link } from "gatsby"
import { motion } from "framer-motion"
// Hooks / State
import { useAppState } from "../../state/appState"

// Components
import { MenuToggle } from "./menuToggle"
import { HomeLink } from "./homeLink"
import { MainNav } from "./mainNav"

import LogoIcon from "../../svg/logo.svg"
// import MenuIcon from "../../svg/menu.svg"

import { UseNavigation } from "../../GraphQL/useNavigation"

// Animation
const headerVariants = {
  enter: {
    opacity: 1,
    y: 0,
    transition: {
      delay: 0.5,
      duration: 0.7,
    },
  },
  exit: {
    opacity: 0,
    y: -100,
    transition: {
      delay: 0,
      duration: 0.7,
    },
  },
}

export const Header = () => {
  const { mainNav, caption } = UseNavigation()
  const {
    toggleNavOpen,
    hideHeader,
    headerUnderline,
  } = useAppState()

  
  
  return (
    <>
      {hideHeader === false && (
        <>
          <motion.header
            variants={headerVariants}
            initial="exit"
            animate="enter"
            exit="exit"
            className={`w-full px-3 py-5 md:px-6 md:py-8 text-current absolute z-30 flex justify-between ${
              headerUnderline ? "lg:border-current border-header" : ""
            }`}
          >
            <Link to="/contact"
              className="cursor-pointer hidden font-serif md:flex md:w-1/3 md:text-xs"
            >
              {caption && caption}
            </Link>
            <div className="md:w-1/3 flex md:justify-center">
              <HomeLink>
                <LogoIcon className="lg:w-44" />
              </HomeLink>
            </div>
            <div className="md:w-1/3 md:flex md:justify-end">
              <MenuToggle onClick={toggleNavOpen}>
                <svg width="32" height="19" viewBox="0 0 32 19">
                  <line
                    className="stroke-current"
                    y1="1.5"
                    x2="32"
                    y2="1.5"
                    stroke-width="3px"
                  />
                  <line
                    className="stroke-current"
                    y1="9.5"
                    x2="32"
                    y2="9.5"
                    stroke-width="3px"
                  />
                  <line
                    className="stroke-current"
                    y1="17.5"
                    x2="32"
                    y2="17.5"
                    stroke-width="3px"
                  />
                </svg>
              </MenuToggle>
            </div>
          </motion.header>
          <MainNav mainNav={mainNav} />
        </>
      )}
    </>
  )
}
