import React from 'react'
import Image from "gatsby-plugin-sanity-image"
import { Link } from "gatsby"
import { motion } from "framer-motion"

import { MotionWraper } from "../blocks/motionWraper"

const aniVars = {
  hidden: {
    opacity: 0,
    scale: 0.9,
  },
  visible: {
    opacity: 1,
    scale: 1,
  },
};

const ProjectCard = ({ page: { portalImage, main }, location, width }) => {
  return (
    <MotionWraper className={`col-span-1 ${width === 'full' && 'lg:col-span-2'}`}>
      <motion.div
        transition={{
          duration: 0.7,
          type: 'ease-in-out',
        }}
        variants={aniVars}
      >
        {main.slug.current && (
          <motion className="overflow-hidden">
            <Link to={main.slug.current}>
              {portalImage && portalImage.image ? (
                <div className="overflow-hidden">
                  <motion.div
                    whileHover={{ scale: 1.05 }}
                    transition={{
                      duration: 0.5,
                      type: 'ease-in-out',
                    }}
                  >
                    <Image className="w-full h-auto" {...portalImage.image} />
                  </motion.div>
                </div>
              ) : (
                <div className="aspect-w-4 aspect-h-3 bg-gradient-to-r from-black to-gray-700 "></div>
              )}
              {location && <p className="mt-4 font-medium text-xs lg:text-lg">{location}</p>}
              {main.title && <h4 className="font-medium text-xl lg:text-3xl">{main.title}</h4>}
            </Link>
          </motion>
        )}
      </motion.div>
    </MotionWraper>
  );
}
 export default ProjectCard
