import React from 'react'
import { motion } from "framer-motion"

// GQL Fragments
import { useCta } from "../../GraphQL/useCta"

// Components
import { MotionWraper } from "../blocks/motionWraper"
import { ButtonLink } from "../ui"
import { PortableTextBlockCta } from "../sanity/portableTextBlockCta"

// Animation
const imageVariants = {
  visible: {
    opacity: 1,
    y: 0,
  },
  hidden: {
    opacity: 0,
    y: 25,
  },
};

const CtaContent = ({spacing}) => {
  const siteConfig = useCta()
  const { _rawCtaHeading, ctaLink } = siteConfig
  return (
    <MotionWraper className={`grid grid-cols-14`} spacing={spacing}>
      <motion.div
        className="col-start-2 col-end-14 flex flex-col items-center justify-center"
        variants={imageVariants}
        transition={{
          type: "ease-out",
          damping: 10,
          delay: 0.3,
          duration: 0.6,
        }}
      >
        {_rawCtaHeading && <PortableTextBlockCta text={_rawCtaHeading} />}        
        {ctaLink && <ButtonLink {...ctaLink} />}
      </motion.div>
    </MotionWraper>
  )
}

export default CtaContent
