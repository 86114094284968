import React from "react"
import PortableText from "react-portable-text"

import { PageLink, PathLink } from "../ui/links"

export const PortableTextBlockCta = ({ text, ...props }) => {
  return (
    <div>
      <PortableText
        // Pass in block content straight from Sanity.io
        className="block-content font-serif"
        content={text}
        // Optionally override marks, decorators, blocks, etc. in a flat
        // structure without doing any gymnastics
        serializers={{
          h1: props => <h1 className="font-sans" style={{ color: "inherit", fontWeight: "normal", lineHeight: '1.7' }} {...props} />,
          h2: props => <h2 className="font-sans uppercase text-xl md:text-3xl lg:text-5xl xl:text-6xl 2xl:text-7xl 3xl:text-8xl font-bold text-center" style={{ color: "inherit", fontWeight: "normal" }} {...props} />,
          h3: props => <h3 className="text-xl md:text-2xl lg:text-2xl 2xl:text-4xl mb-8" style={{ color: "inherit", fontWeight: "normal", marginTop: "0" }} {...props} />,
          h4: props => <h4 className="font-sans" style={{ color: "inherit", fontWeight: "normal" }} {...props} />,
          h5: props => <h5 className="font-sans" style={{ color: "inherit", fontWeight: "normal" }} {...props} />,
          h6: props => <h6 className="font-sans" style={{ color: "inherit", fontWeight: "normal" }} {...props} />,
          strong: props => <strong className="font-sans block mb-2 tracking-wide" style={{ fontWeight: "bold" }} {...props} />,
          pageLink: <PageLink {...props} />,
          pathLink: <PathLink {...props} />,
        }}
      />
    </div>
  )
}
