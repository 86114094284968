import React from 'react'
import { Link } from "gatsby"
import Image from "gatsby-plugin-sanity-image"
import { motion } from "framer-motion"

import { MotionWraper } from '../blocks/motionWraper';

const aniVars = {
  hidden: {
    opacity: 0,
    scale: 0.9,
  },
  visible: {
    opacity: 1,
    scale: 1,
  },
};

const BlogCard = ({ className, item}) => {
  const  { portalImage, category, date, main: { title, slug} } = item.node
  return (
    <MotionWraper>
      <motion.div
        transition={{
          duration: 0.7,
          type: 'ease-in-out',
        }}
        variants={aniVars}
      >
        <Link to={`/journal/${slug.current}`} className={`${className}`}>
          {portalImage && portalImage.image ? (
            <div className="overflow-hidden">
              <motion.div
                className=""
                whileHover={{ scale: 1.05 }}
                transition={{
                  duration: 0.5,
                  type: 'ease-in-out',
                }}
              >
                <Image
                  style={{ maxHeight: '75vh'}}
                  className="w-full h-auto object-cover"
                  {...portalImage.image}
                />
              </motion.div>
            </div>
          ) : (
            <div className="aspect-w-4 aspect-h-3 bg-gradient-to-r from-black to-gray-700 "></div>
          )}
          <p className="mt-6 font-sm font-medium">
            {category && category.main.title && category.main.title}
          </p>
          <h4 className="md:mt-2 md:mb-4 text-xl lg:text-3xl font-medium">{title && title}</h4>
          {date && <p className="text-xs lg:text-base font-serif">{date}</p>}
        </Link>
      </motion.div>
    </MotionWraper>
  );
}

export default BlogCard
