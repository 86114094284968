import React from 'react'
import { useEffect, useState } from "react"
import {
  motion,
  useViewportScroll,
  useSpring,
  useTransform,
} from "framer-motion"
import { Link } from "gatsby"

// Animation
const variants = {
  visible: {
    opacity: 1,
    scale: 1,
    transition: {
      delay: 1.3,
      duration: 0.9,
    },
  },
  hidden: {
    opacity: 0,
    scale: 0.7,
    transition: {      
      duration: 0.5,
    },
  },
}

const ScrollProgress = ({ target }) => {
   const [isComplete, setIsComplete] = useState(false)
   const { scrollYProgress } = useViewportScroll()
   const yRange = useTransform(scrollYProgress, [0, 0.9], [0, 1])
   const pathLength = useSpring(yRange, { stiffness: 400, damping: 90 })

   useEffect(() => yRange.onChange(v => setIsComplete(v >= 1)), [yRange])

  return (
    <motion.div
      data-scroll
      data-scroll-sticky
      data-scroll-position="top"
      data-scroll-target={`#progress-wrapper`}
      isComplete={isComplete}
      variants={variants}
      initial="hidden"
      animate="visible"
      exit="hidden"
      className="fixed z-20 w-8 h-8 lg:w-12 lg:h-12 top-2 right-2 lg:top-4 lg:right-4"
    >
      <Link to={`${target}`} className="cursor-pointer">
        <svg
          style={{ width: '100%', height: '100%' }}
          className="overflow-visible"
          viewBox="0 0 53 53"
        >
          <motion.path
            fill="#F95D5D"
            strokeWidth="2"
            stroke="black"
            strokeDasharray="0 1"
            d="M.94,27.07a26,26,0,1,0,26-26,25.95,25.95,0,0,0-26,26"
            transform="translate(-0.44 -0.57)"
            transition={{ duration: 0.5 }}
            style={{
              pathLength,
              rotate: 90,
              scaleX: -1, // Reverse direction of line animation
            }}
          />
          <path
            d="M15.84,18l2.1-2.1c3,3,6.1,6,9,9,3-3,6.1-6.1,9.1-9l2.1,2.1-9,9c3,3,6,6.1,9,9.1L36,38.17c-3-3-6.1-6.1-9.1-9l-9,9-2.1-2.1c3-3,6-6.1,9-9.1Q20.34,22.62,15.84,18Z"
            transform="translate(-0.44 -0.57)"
            fill="black"
          />
        </svg>
        {/* <svg
          className="w-full h-full"
          viewBox="0 0 60 60"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <circle cx="30" cy="30" r="30" fill="#F95D5D" />
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M20 20.9474C20.6491 20.2983 21.2983 19.6491 21.9474 19C24.8037 21.8238 27.66 24.6801 30.4838 27.5363C33.34 24.6801 36.1963 21.8238 39.0526 19C39.7017 19.6491 40.3509 20.2983 41 20.9474C38.1437 23.8037 35.2875 26.66 32.4637 29.4838C35.2875 32.34 38.1437 35.1963 41 38.0526C40.3509 38.7017 39.7017 39.3509 39.0526 40C36.1963 37.1437 33.34 34.2875 30.4838 31.4637C27.66 34.2875 24.8037 37.1437 21.9474 40C21.2983 39.3509 20.6491 38.7017 20 38.0526C22.8238 35.1963 25.6801 32.34 28.5363 29.4838C25.6801 26.66 22.8238 23.8037 20 20.9474Z"
            fill="black"
          />
        </svg> */}
      </Link>
    </motion.div>
  );
}

export default ScrollProgress


