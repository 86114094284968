import React from "react"
import { Link } from "gatsby"

// Hooks, State
import { useAppState } from "../../state/appState"

export const HomeLink = ({children}) => {  
  const { closeNav } = useAppState()
  return (
    <Link to="/" className={`fill-current`} onClick={closeNav}>
      {children}
    </Link>
  )
}
