export const useTailPadBot = (size) => {
  switch (size) {
    case '1':
      return 'pb-8 lg:pb-16 2xl:pb-24';
    case '2':
      return "pb-16 lg:pb-32 2xl:pb-64"
    case '3':
      return "pb-24 lg:pb-48 2xl:pb-96"    
    default:
      return 'pb-0';
  }
};
