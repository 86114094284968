import { graphql, useStaticQuery } from "gatsby"

import { QueryFragments } from "./queryFragments" //eslint-disable-line

export const UseFooter = () => {
  const { sanityFooter } = useStaticQuery(graphql`
    query {
      sanityFooter {
        emailAddress
        facebook
        instagram
        phoneNumber
        addressLine1
        addressLine2
      }
    }
  `)
  return sanityFooter || {}
}
