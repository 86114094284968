export const useTailPadTop = (size) => {
  switch (size) {
    case '1':
      return "pt-8 lg:pt-16 2xl:pt-32"
    case '2':
      return "pt-16 lg:pt-32 2xl:pt-64"
    case '3':
      return "pt-24 lg:pt-48 2xl:pt-96"    
    default:
      return 'pt-0';
  }
};
