import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { AnimatePresence, motion } from 'framer-motion';
import { useLocation } from '@reach/router';

import './layout.css';

// Hooks, Querys & State
import { useAppState } from '../state/appState';

// Components
import { Header } from './navigation/header';
import { Footer } from './navigation/footer';

const aniVars = {
  enter: {
    opacity: 1,
  },
  exit: {
    opacity: 0,
  },
};

const Layout = ({ children }) => {
  const { globalTheme, hideFooter } = useAppState();

  const { pathname } = useLocation();

  useEffect(() => {
    if (globalTheme === 'dark') {
      document.documentElement.classList.add('theme-dark');
      document.documentElement.classList.remove('theme-light');
      document.documentElement.classList.remove('theme-pink');
    } else if (globalTheme === 'pink') {
      document.documentElement.classList.add('theme-pink');
      document.documentElement.classList.remove('theme-light');
      document.documentElement.classList.remove('theme-dark');
    } else if (globalTheme === 'light') {
      document.documentElement.classList.add('theme-light');
      document.documentElement.classList.remove('theme-dark');
      document.documentElement.classList.remove('theme-pink');
    }
  }, [globalTheme]);

  return (
    <div id="page-wrapper" className={`antialiased w-full min-h-screen transition-all duration-300`}>
      <Header />
      <AnimatePresence exitBeforeEnter>
        <motion.main
          id="container"
          className={` w-full relative`}
          key={pathname}
          variants={aniVars}
          initial="exit"
          animate="enter"
          exit="exit"
          transition={{
            ease: 'easeInOut',
            duration: 0.5,
          }}
        >
          <>{children}</>
          {hideFooter ? '' : <Footer className={`flex lg:pb-20`} />}
        </motion.main>
      </AnimatePresence>
    </div>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};
export default Layout;
