import React from "react"
import { Link } from "gatsby"

// Hooks & Querys
import { useSanityPath } from "../../hooks"

export const PageLink = ({ className, page, linkText, ...rest }) => {
  const path = useSanityPath(page)
  return (
    <Link className={`${className}`} to={path} {...rest}>
      {linkText}
    </Link>
  )
}

export const ExternalLinkPortable = ({ className, link, linkText, ...rest }) => {
  return (
    <a className={`${className} font-bold font-sans cursor-pointer`} href={link} target="_blank" rel="noreferrer" {...rest}>
      {linkText}
    </a>
  );
};


export const PageLinkPortable = ({ className, page, linkText, ...rest }) => {
  const path = useSanityPath(page)
  return (
    <Link className={`${className} font-bold underline font-sans`} to={path} {...rest}>
      {linkText}
    </Link>
  )
}

export const PathLink = ({ path, linkText, ...rest }) => {
  return (
    <Link to={`/${path}`} {...rest}>
      {linkText}
    </Link>
  )
}

export const ButtonLink = ({ page, linkText, className, ...rest }) => {
  const path = useSanityPath(page)
  return (
    <Link to={`${path}`} {...rest} className={`mt-8 py-4 px-16 rounded-full bg-pink text-black ${className}`}>
      {linkText}
    </Link>
  )
}
