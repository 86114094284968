import React from 'react'

import { UseFooter } from "../../GraphQL/useFooter"




export const Footer = ({ className }) => {
  const {
    emailAddress,
    facebook,
    instagram,
    phoneNumber,
    addressLine1,
    addressLine2,
  } = UseFooter()
  return (    
      <footer
        className={`${className} font-serif w-full flex-col md:flex-row  justify-between items-center md:items-start px-3 py-5 md:px-6 md:py-8`}
      >
        <div          
          className="w-full md:w-1/4 mb-6 md:mb-0 flex flex-col items-center"
        >
          {phoneNumber}
          <a href={'mailto:' + emailAddress}>{emailAddress}</a>
        </div>
        <div          
          className="w-full md:w-1/4 mb-6 md:mb-0 flex flex-col items-center"
        >
          {addressLine1}
          {addressLine2 && (
            <>
              <br />
              {addressLine2}
            </>
          )}
        </div>
        <div          
          className="w-full md:w-1/4 mb-6 md:mb-0 flex flex-col items-center"
        >
          <a target="_blank" rel="noreferrer" href={facebook}>
            Facebook
          </a>
          <a target="_blank" rel="noreferrer" href={instagram}>
            Instagram
          </a>
        </div>
      </footer>
  );
}
