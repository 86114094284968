import React from "react"
import { motion, AnimatePresence } from "framer-motion"

// Hooks, State
import { useAppState } from "../../state/appState"
import { HomeLink } from "./homeLink"
import { MenuToggle } from "./menuToggle"

import LogoRound from "../../svg/logo-round.svg"
import CloseIcon from "../../svg/close.svg"

// Components
import { Footer } from "./footer"
import { PageLink, PathLink } from "../ui"

// Animation Variants
const navWrapper = {
  closed: {
    opacity: 0,
    transition: {
      duration: 0.5,
      ease: [0.43, 0.13, 0.23, 0.96],
    },
  },
  open: {
    opacity: 1,
    transition: {
      duration: 0.5,
      ease: [0.43, 0.13, 0.23, 0.96],
    },
  },
}

const logoVariants = {
  closed: {
    opacity: 0,
    rotate: -180,
    transition: {
      duration: 0.5,
      ease: [0.43, 0.13, 0.23, 0.96],
    },
  },
  open: {
    opacity: 1,
    rotate: 0,
    transition: {
      duration: 1,
      ease: [0.43, 0.13, 0.23, 0.96],
    },
  },
  exit: {
    opacity: 0,
    transition: {
      duration: 0.75,
    },
  },
}

const footerVariants = {
  closed: {
    opacity: 0,
    y: "100%",
    transition: {
      duration: 0.5,
      ease: [0.43, 0.13, 0.23, 0.96],
    },
  },
  open: {
    opacity: 1,
    y: "0%",
    transition: {
      duration: 0.75,
      delay: 0.5,
      ease: [0.43, 0.13, 0.23, 0.96],
    },
  },
}

const MainNavInner = ({ mainNav }) => {
  const { closeNav } = useAppState()
  return (
    <motion.div
      className="fixed h-screen w-full bg-black flex items-center justify-center flex-col z-40 top-0 left-0"
      variants={navWrapper}
      initial="closed"
      animate="open"
      exit="closed"
    >
      <div className="absolute top-0 left-0 w-full flex justify-between items-start px-3 py-5 md:px-6 md:py-8">
        <motion.div variants={logoVariants}>
          <HomeLink>
            <LogoRound exit="exit" onClick={closeNav} />
          </HomeLink>
        </motion.div>
        <MenuToggle>
          <CloseIcon onClick={closeNav} />
        </MenuToggle>
      </div>
      <nav className="text-white font-bold flex flex-col lg:w-4/5 lg:flex-row lg:justify-between items-center uppercase">
        {mainNav.map(item =>
          item._type === "pageLink" ? (
            <PageLink
              {...item}
              className="serif text-2xl md:text-4xl 2xl:text-5xl hover:text-pink transition-colors my-4"
              activeClassName="text-pink"
              onClick={closeNav}
            />
          ) : (
            <PathLink
              {...item}
              className="serif text-2xl md:text-4xl 2xl:text-5xl hover:text-pink transition-colors my-4"
              activeClassName="text-pink"
              onClick={closeNav}
            />
          )
        )}
      </nav>
      <motion.div
        variants={footerVariants}
        className="absolute w-full bottom-0 left-0 hidden md:flex"
      >
        <Footer className={`flex text-pink`} />
      </motion.div>
    </motion.div>
  )
}

export const MainNav = ({ mainNav }) => {
  const { navOpen } = useAppState()
  return (
    <AnimatePresence navOpen={navOpen}>
      {navOpen && <MainNavInner mainNav={mainNav} />}
    </AnimatePresence>
  )
}
