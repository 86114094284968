import React, { createContext, useContext, useState } from "react"

// Hooks
import { useToggle } from "../hooks"

export const AppState = createContext({
  navOpen: false,
  headerPinned: false,
  globalTheme: "dark",
  hideFooter: false,
  headerUnderline: false,
})

export const AppWrapper = ({ children }) => {
  const [navOpen, setNavOpen, toggleNavOpen] = useToggle(false)
  const [hideFooter, toggleHideFooter] = useToggle(false)
  const [hideHeader, toggleHideHeader] = useToggle(false)
  const [headerUnderline, toggleHeaderUnderline] = useToggle(false)

  const closeNav = () => {
    setNavOpen(false)
  }

  // Page Theme
  const [globalTheme, setGlobalTheme] = useState(null)

  return (
    <AppState.Provider
      value={{
        navOpen,
        toggleNavOpen,
        closeNav,
        setNavOpen,
        globalTheme,
        setGlobalTheme,
        hideFooter,
        toggleHideFooter,
        hideHeader,
        toggleHideHeader,
        headerUnderline,
        toggleHeaderUnderline,
      }}
    >
      {children}
    </AppState.Provider>
  )
}

export const useAppState = () => useContext(AppState)
