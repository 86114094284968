import React, { useEffect } from "react"
import { motion, useAnimation } from "framer-motion"
import { useInView } from "react-intersection-observer"
// Hooks
import { useTailPadBot, useTailPadTop } from "../../hooks"



const aniVars = {
  hidden: {
    opacity:0
  },
  visible: {
    opacity: 1,
  }
  
}

export const MotionWraper = ({ spacing, children, className, rest }) => {
  
  const paddingTop = useTailPadTop((spacing && spacing.padTop) || "0")
  const paddingBottom = useTailPadBot((spacing && spacing.padBot) || "0")

  const controls = useAnimation()
  const [ref, inView] = useInView({ threshold: 0.1, triggerOnce:true })


  useEffect(() => {
    if (inView) {
      controls.start("visible")
    }
  }, [controls, inView])

  return (
    <motion.section
      className={`${paddingTop} ${paddingBottom} ${className}`}      
      initial="hidden"
      animate={inView ? "visible" : "hidden"}
      variants={aniVars}
      {...rest}
    >
      <div ref={ref}/>
      {children}
    </motion.section>
  )
}

