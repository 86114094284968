export const useSanityPath = props => {
  const { _type, main, slug } = props || {}

  function getPath(_type, slug) {
    
    switch (_type) {
      case "page":
        return `/${slug.current}`
      case "standardPage":
        return `/${main.slug.current}`
      case "project":
        return `/projects/${main.slug.current}`
      case "post":
        return `/blog/${slug.current}`
      case "homePage":
        return `/`
      default:
        return null
    }
  }

  const path = getPath(_type, slug, main)

  return path
}
