import { graphql, useStaticQuery } from "gatsby"

import { QueryFragments } from "./queryFragments" //eslint-disable-line

export const UseNavigation = () => {
  const { sanityNavigation } = useStaticQuery(graphql`
    query {
      sanityNavigation {
        caption
        mainNav {
          ... on SanityPageLink {
            _type
            ...PageLinkFields
          }
          ... on SanityPathLink {
            _type
            ...PathLinkFields
          }
        }
      }
    }
  `)
  return sanityNavigation || {}
}
