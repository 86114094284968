import { graphql, useStaticQuery } from "gatsby"

import { QueryFragments } from "./queryFragments" //eslint-disable-line

export const useCta = () => {
  const { sanitySiteConfig } = useStaticQuery(graphql`
    query {
      sanitySiteConfig {
        _rawCtaHeading(resolveReferences: { maxDepth: 10 })
        ctaLink {
          ...PageLinkFields
        }
      }
    }
  `)
  return sanitySiteConfig || {}
}
